import {
    codeforqueen, cricket, evoke, fantasy,
    hashtag, ipl, novice, olpc, query,
    starlight, takeshi, ospc, a2a, chess,
    ml, quiz, sitesculpt, sourcecode, badminton
} from '../assets/SingleEvents';

import appian from '../assets/Sponsors/appian.png';
import rq from '../assets/Sponsors/rq.png';
import valor from '../assets/Sponsors/valor.png';

import chess_qr from '../assets/Events/chess_qr.jpg';
import game_qr from '../assets/Events/game_qr.jpg';
import game from '../assets/SingleEvents/game.jpg'

export const events = [
    {
        title: "Gamindrome",
        team: "One member",
        code: 7202,
        eventClosed: true,
        prize: '4k+',
        price: 50,
        maxTeam: 1,
        qr: game_qr,
        image: game,
        type: "nontech",
        body: `Teams compete in a single-elimination style tournament in Gamindrome, a gaming contest bringing together participants for a competitive and strategic gaming experience.`,
        tag: "Technical Events are twinkle twinkle little star",
        to: "gamindrome",
        rounds: [
            {
                title: "Round 1",
                content: "Knockout",
                duration: "09:00 AM to 05:00 PM",
                venue: "Third Floor Lab",
                date: "22 March 2024"
            },
        ],
        contacts: [
            { name: 'Surendira Balaji', phno: '9790925246' },
            { name: 'Vishaal S', phno: '9677124743' },
            { name: 'Raghul', phno: '9884062320' },
        ]
    },
    {
        title: "Chess",
        club: "Chess Club",
        team: "One member",
        prize: '4k+',
        code: 7201,
        eventClosed: true,
        price: 30,
        maxTeam: 1,
        qr: chess_qr,
        image: chess,
        type: "nontech",
        body: `A blitz chess tournament where players battle against time and each other in a fast-paced and strategic competition.
        `,
        tag: "Technical Events are twinkle twinkle little star",
        to: "chess",
        rounds: [
            {
                title: "Round 1",
                content: "Knockout chess game",
                duration: "1:00 PM to 5:00 PM",
                venue: "RUSA Gallery",
                date: "21 March 2024"
            },
        ],
        contacts: [
            { name: 'Parithi', phno: '9361868813' },
            { name: 'Aravind', phno: '7358627278' },
        ]
    },
    {
        title: "Starlight Coding",
        team: "Two to three members",
        code: 5404,
        eventClosed: true,
        prize: '18k+',
        image: starlight,
        type: "tech",
        body: 'An all-night Coding Extravaganza featuring diverse challenges in DSA, fostering teamwork and endurance. Participants colLab, DCSEorate across different CS domains, tackling various tracks in this tech marathon.',
        tag: "Technical Events are twinkle twinkle little star",
        to: "stalight",
        rounds: [
            {
                title: "Round 1",
                content: "Rolling Event (30 minutes)",
                duration: "9:00 AM to 1:30 PM",
                venue: "Second Floor Lab, DCSE",
                date: "22 March 2024"
            },
            {
                title: "Round 2",
                content: "Coding challenges",
                duration: "9:00 PM to 6:00 AM",
                venue: "Ground Floor Lab, DCSE",
                date: "22 to 23 March 2024"
            },
        ],
        internships: [
            { name: 'ROOTQUOTIENT', image: rq },
        ],
        contacts: [
            { name: 'Ashwin M', phno: '9360523558' },
            { name: 'Navaneeth', phno: '9042142160' },
        ]
    },
    {
        title: "Novice.Init()",
        team: "One or two members",
        code: 5412,
        eventClosed: true,
        prize: '7k+',
        image: novice,
        type: "tech",
        body: `Designed exclusively for first-year students, this programming event introduces them to Computer Science by testing logical reasoning and basic programming skills. Geared towards coding novices, it explores hurdles in reasoning, arithmetic, and programming, evaluating participants' ability to build primitive algorithms and tackle mathematical calculations.
        `,
        tag: "Technical Events are twinkle twinkle little star",
        to: "novice",
        rounds: [
            {
                title: "Round 1",
                content: "MCQs",
                duration: "9:00 AM to 12:00 PM",
                venue: "RUSA Gallery",
                date: "21 March 2024"
            },
            {
                title: "Round 2",
                content: "Hackerrank Contest",
                duration: "10:30 AM to 12:30 PM",
                venue: "Third Floor Lab, DCSE",
                date: "23 March 2024"
            },
        ],
        contacts: [
            { name: 'Ajai Krishna', phno: '7010707652' },
            { name: 'Renjitha K', phno: '7907854731' },
            { name: 'Sanjena G', phno: '9003168373' },
        ]
    },
    {
        title: "Hashtag Contest",
        team: "One member",
        code: 5409,
        eventClosed: false,
        image: hashtag,
        prize: '7k+',
        hashtagLink: 'https://forms.gle/HjhF8TwaKNWdED6GA',
        type: "preevents",
        body: `A fun and creative platform, this event encourages participants to share humorous memes, showcasing their wit and contributing to community comedy. The participant will be given a theme or a template and they will have to use their creativity to make a meme or reel out of it.
        `,
        tag: "Technical Events are twinkle twinkle little star",
        to: "hashtagcontest",
        rounds: [
            {
                title: "Round 1",
                content: "Creating reels or memes",
                duration: "Upto March 22, 11:59 PM",
            },
        ],
        contacts: [
            { name: 'Sathya', phno: '9445446158' },
            { name: 'Sankar', phno: '8056485198' },
        ]
    },
    {
        title: "OLPC",
        team: "One member",
        code: 5401,
        prize: '4k+',
        image: olpc,
        type: "preevents",
        eventClosed: true,
        body: 'An Online programming contest specially curated for problem solvers and critical thinkers. The students are given interesting problems and they are expected to produce optimized solutions.',
        tag: "Technical Events are twinkle twinkle little star",
        to: "olpc",
        rounds: [
            {
                title: "Round 1",
                content: "GeeksforGeeks Contest",
                duration: "8:30 PM to 10:00 PM",
                venue: "Held online",
                date: "19 March 2024"
            },
        ],
        internships: [
            { name: 'APPIAN', image: appian },
        ],
        contacts: [
            { name: 'Navaneeth', phno: '9042142160' },
            { name: 'Sruthi', phno: '8056284967' },
        ]
    },
    {
        title: "OSPC",
        team: "Two or three members",
        code: 5402,
        image: ospc,
        prize: '7k+',
        eventClosed: true,
        type: "tech",
        body: ' A fast-paced programming contest, where the participants tackle intricate problems in data structures and algorithms. The participants set out to pitch their geekiness and compete both against the clock and other seasoned contestants. The contest calls for your competitive programming ability and approach towards problem solving.',
        tag: "Technical Events are twinkle twinkle little star",
        to: "ospc",
        rounds: [
            {
                title: "Round 1",
                content: "MCQ pen and paper event",
                duration: "9:00 AM to 12:00 PM",
                venue: "RUSA Gallery",
                date: "22 March 2024"
            },
            {
                title: "Round 2",
                content: "Hackerrank Test",
                duration: "1:00 PM to 4:00 PM",
                venue: "Ground Floor Lab, DCSE",
                date: "23 March 2024"
            },
        ],
        internships: [
            { name: 'APPIAN', image: appian },
        ],
        contacts: [
            { name: 'Renjitha K', phno: '7907854731' },
            { name: 'Karthikeyan G', phno: '9677455861' },
        ]
    },
    {
        title: "Code for Queens",
        team: "Two or three members",
        code: 5403,
        eventClosed: true,
        prize: '7k+',
        image: codeforqueen,
        type: "tech",
        body: 'A Coding event mainly targeted for women. A fast-paced programming contest, where the participants tackle intricate problems in data structures and algorithms. The participants set out to pitch their geekiness and compete both against the clock and other seasoned contestants. The contest calls for your competitive programming ability and approach towards problem solving.',
        tag: "Technical Events are twinkle twinkle little star",
        to: "codeforqueens",
        rounds: [
            {
                title: "Round 1",
                content: "Crossword",
                duration: "9:00 AM to 12:00 PM",
                venue: "KP Room No. 303",
                date: "21 March 2024"
            },
            {
                title: "Round 2",
                content: "Hackerrank Test",
                duration: "2:00 PM to 5:00 PM",
                venue: "Third Floor Lab, DCSE",
                date: "21 March 2024"
            },
        ],
        contacts: [
            { name: 'Dhivya S', phno: '8778119032' },
            { name: 'Keerthika B', phno: '8148457108' },
            { name: 'Varsha', phno: '6382952323' },
        ]
    },
    {
        title: "IPL Auction",
        team: "One to Three members",
        code: 5406,
        eventClosed: false,
        prize: '4k+',
        image: ipl,
        type: "nontech",
        body: 'An exciting auction event for IPL fans where participants can bid on their favorite cricket players. IPL Auction adds an element of strategy and excitement to the gaming experience, allowing participants to build their dream teams.',
        tag: "Technical Events are twinkle twinkle little star",
        to: "auction",
        rounds: [
            {
                title: "Round 1",
                content: "MCQ",
                duration: "9:00 AM to 12:00 PM",
                venue: "RUSA Gallery",
                date: "23 March 2024"
            },
            {
                title: "Round 2",
                content: "Auction",
                duration: "1:00 PM to 5:00 PM",
                venue: "Turing Hall",
                date: "23 March 2024"
            },
        ],
        contacts: [
            { name: 'Karthikeyan G', phno: '9677455861' },
            { name: 'Hari Anandh S', phno: '8667081882' },
        ]
    },
    {
        title: "Query Quest",
        team: "Two members",
        code: 5407,
        eventClosed: false,
        prize: '5k+',
        image: query,
        type: "tech",
        body: `A DBMS challenge that combines theoretical knowledge with practical application. The first round involves DBMS-based multiple-choice questions (MCQs), testing participants' understanding of database concepts. Successful participants advance to Round 2, a hands-on SQL coding challenge, where they apply their skills to solve real-world database problems.`,
        tag: "Technical Events are twinkle twinkle little star",
        to: "queryquest",
        rounds: [
            {
                title: "Round 1",
                content: "MCQ",
                duration: "9:00 AM to 12:00 PM",
                venue: "KP Room No. 307",
                date: "23 March 2024"
            },
            {
                title: "Round 2",
                content: "Competitive Question",
                duration: "1:00 PM to 4:00 PM",
                venue: "Ground Floor Lab, DCSE",
                date: "23 March 2024"
            },
        ],
        contacts: [
            { name: 'Ajai Krishna', phno: '7010707652' },
            { name: 'Karthikeyan G', phno: '9677455861' },
        ]
    },
    {
        title: "Design-a-thon",
        team: "One or two members",
        code: 5408,
        eventClosed: true,
        image: evoke,
        prize: '3k+',
        type: "nontech",
        body: `Armed with your favorite design tools like Figma or Canva, you'll have the opportunity to craft stunning visuals that push the boundaries of creativity. 🖌💡`,
        tag: "Technical Events are twinkle twinkle little star",
        to: "evokeui",
        rounds: [
            {
                title: "Round 1",
                content: "Creating design",
                duration: "9:00 AM to 12:00 PM",
                venue: "Ground Floor Lab, DCSE",
                date: "21 March 2024"
            },
        ],
        contacts: [
            { name: 'Nandhini', phno: '9110387681' },
            { name: 'Harshini', phno: '9840448087' },
        ]
    },
    {
        title: "Fantasy League",
        team: "One member",
        code: 5410,
        eventClosed: true,
        prize: '2.5k+',
        image: fantasy,
        type: "preevents",
        body: `It is a website based event. This event goes for 2 days. It is like dream11, where participants can buy the players based on points. After the match scores are calculated and leaderboard are updated.
        `,
        tag: "Technical Events are twinkle twinkle little star",
        to: "fantasyleague",
        rounds: [
            {
                title: "Round 1",
                content: "Online Event",
                duration: "9:00 AM to 9:00 PM",
                venue: "Fantasy League Website",
                date: "19 & 20 March 2024"
            },
        ],
        contacts: [
            { name: 'Nikhil Prasanna', phno: '6379066510' },
            { name: 'Navaneeth', phno: '9042142160' },
        ]
    },
    {
        title: "Takeshi Castle Coding",
        club: "ACM",
        team: "Two member",
        prize: '5k+',
        code: 5411,
        eventClosed: true,
        image: takeshi,
        type: "tech",
        body: `Takeshi Castle Coding is an exhilarating coding event inspired by Takeshi's Castle, featuring rounds with unique obstacles and challenges. Participants code their way through the adventure, conquering hurdles, showcasing skills, and aiming to become the ultimate Code Champion.
        `,
        tag: "Technical Events are twinkle twinkle little star",
        to: "takeshi",
        rounds: [
            {
                title: "Round 1",
                content: "MCQs",
                duration: "9:00 AM to 12:00 PM",
                venue: "KP Room No. 210 & 211",
                date: "21 March 2024"
            },
            {
                title: "Round 2",
                content: "Hackerrank Contest",
                duration: "2:00 PM to 5:00 PM",
                venue: "Third Floor Lab, DCSE",
                date: "21 March 2024"
            },
        ],
        internships: [
            { name: 'VALORPAY', image: valor },
            { name: 'APPIAN', image: appian },
        ],
        contacts: [
            { name: 'Arunachalam', phno: '9551540001' },
            { name: 'Navaneeth', phno: '9042142160' },
        ]
    },
    {
        title: "Source Code",
        club: "CSAU",
        team: "One or two members",
        code: 5413,
        prize: '5k+',
        eventClosed: true,
        image: sourcecode,
        type: "tech",
        body: `A dual coding event testing participants' debugging skills. Given programs overflowing with bugs, participants must resolve errors, making the program run as expected and produce the correct results. Along with this they will have some reverse coding questions.
        `,
        tag: "Technical Events are twinkle twinkle little star",
        to: "sourcecode",
        rounds: [
            {
                title: "Round 1",
                content: "MCQs",
                duration: "9:00 AM to 12:00 PM",
                venue: "KP Room No. 307",
                date: "22 March 2024"
            },
            {
                title: "Round 2",
                content: "Coding Round",
                duration: "1:00 PM to 4:00 PM",
                venue: "Second Floor Lab, DCSE",
                date: "22 March 2024"
            },
        ],
        contacts: [
            { name: 'Ajai Krishna', phno: '7010707652' },
            { name: ' Akshaya', phno: '9952909551' },
        ]
    },
    {
        title: "SiteSculpt",
        team: "One to three members",
        code: 5414,
        prize: '5k+',
        eventClosed: true,
        image: sitesculpt,
        type: "tech",
        body: `In this hands-on coding event, participants tackle a dual challenge of front-end finesse and back-end brilliance, showcasing their coding prowess. A creative competition where minds compete to demonstrate skills in website development, participants delve into creating captivating sites against the clock, testing their web development knowledge and replication abilities.
        `,
        tag: "Technical Events are twinkle twinkle little star",
        to: "sitesculpt",
        rounds: [
            {
                title: "Round 1",
                content: "Web Development",
                duration: "1:00 PM to 5:00 PM",
                venue: "Third Floor Lab, DCSE",
                date: "22 March 2024"
            },
        ],
        contacts: [
            { name: 'Smrithi', phno: '8072869255' },
            { name: 'Varsha', phno: '6382952323' },
        ]
    },
    {
        title: "ML Mania",
        team: "One to three members",
        code: 5415,
        eventClosed: true,
        prize: '5k+',
        image: ml,
        type: "tech",
        body: ` It is 2-3 hrs event where participants will be given with easy ML tasks (like first MCQ’s, filling the things missed in jupyter notebook, basic regression or classification model train). They can refer the internet.
        `,
        tag: "Technical Events are twinkle twinkle little star",
        to: "mlmania",
        rounds: [
            {
                title: "Round 1",
                content: "MCQs, ML models",
                duration: "1:00 PM to 5:00 PM",
                venue: "Second Floor Lab, DCSE",
                date: "21 March 2024"
            },
        ],
        contacts: [
            { name: 'Ajai Krishna', phno: '7010707652' },
            { name: 'Renjitha K', phno: '7907854731' },
        ]
    },
    {
        title: "Entertainment Quiz",
        club: "Quiz Club",
        team: "Two to three members",
        code: 5416,
        prize: '3k+',
        eventClosed: true,
        image: quiz,
        type: "nontech",
        body: `A fun event where the die-hard Cinema Kannis (nerds), TV show fanatics, anime lovers race against each other to answer questions.  Dive into the captivating world of cinema, honoring iconic figures who have graced prestigious stages from Filmfare to Oscars. From timeless classics to the latest TV shows and anime sensations, this event promises excitement for all! Test your expertise and compete with fellow fans in a celebration of cinematic brilliance.`,
        tag: "Technical Events are twinkle twinkle little star",
        to: "quiz",
        rounds: [
            {
                title: "Round 1",
                content: "Rolling Event",
                roundDesc: [
                    '45 minutes per heat',
                    'First heat: 9:15 AM to 10:00 AM',
                    'Second heat: 10:00 AM to 10:45 AM',
                    'Third heat: 10:45 AM to 11:30 AM',
                    'Fourth heat: 11:30 AM to 12:15 PM',
                ],
                duration: "9:00 AM to 12:15 PM",
                venue: "Turing Hall",
                date: "22 March 2024"
            },
            {
                title: "Round 2",
                content: "Quiz",
                duration: "1:00 PM to 5:00 PM",
                venue: "Turing Hall",
                date: "22 March 2024"
            },
        ],
        contacts: [
            { name: 'Sairam', phno: '9445070858' },
            { name: 'Aswin', phno: '9789876819' },
        ]
    },
    {
        title: "A2A - Allies to Adversaries",
        team: "Two to three members",
        code: 5418,
        prize: '3k+',
        eventClosed: false,
        image: a2a,
        type: "nontech",
        body: `Welcome to A2A, a two-round competition that transforms allies into adversaries! Group ADZAP Competition. In Round 1, individual members come together as allies within their teams to compete in the Group ADZAP competition. Each team must colLab, DCSEorate to create compelling advertisements under time pressure, showcasing their creativity and teamwork. Only the top performing teams will advance to the next round. Individual Channel Surfing Event,  Round 2 ups the ante as former allies become adversaries in the Individual Channel Surfing event. Contestants face a series of challenges, testing their versatility and adaptability across various TV channels and mediums. From persuasive pitches to impromptu storytelling, contestants must outshine their former teammates to emerge victorious.

        `,
        tag: "Technical Events are twinkle twinkle little star",
        to: "a2a",
        rounds: [
            {
                title: "Round 1",
                content: "Creative Ads",
                duration: "9:00 AM to 12:00 PM",
                venue: "KP Room No. 210 & 211",
                date: "23 March 2024"
            },
            {
                title: "Round 2",
                content: "Impromptu storytelling",
                duration: "1:00 PM to 5:00 PM",
                venue: "KP Room No. 210 & 211",
                date: "23 March 2024"
            },
        ],
        contacts: [
            { name: 'Sanjena G', phno: '9003168373' },
            { name: 'Dharshana R', phno: '6382872625' },
        ]
    },
    {
        title: "Cricket",
        team: "11 members",
        code: 5405,
        image: cricket,
        prize: '1.5k+',
        type: "preevents",
        eventClosed: true,
        body: 'A thrilling 6-over cricket tournament played in knockout fashion, uniting department teams to compete for the esteemed "Abacus Trophy," fostering teamwork, talent, and sportsmanship.',
        tag: "Technical Events are twinkle twinkle little star",
        to: "cricket",
        rounds: [
            {
                title: "Round 1",
                content: "Knockout Matches",
                duration: "Flexible (6 overs per innings)",
            },
        ],
        contacts: [
            { name: 'Arikaran', phno: '6384231398' },
            { name: 'Deepak', phno: '9047336520' },
        ]
    },
    {
        title: "Badminton",
        team: "One member",
        code: 5419,
        eventClosed: true,
        prize: '1.5k+',
        image: badminton,
        type: "preevents",
        body: `Badminton is a thrilling and dynamic sport that showcases the agility, speed, and tactical prowess of female players. Played with finesse and precision,our badminton matches captivate audiences with their fast-paced rallies and strategic gameplay.
        `,
        tag: "Technical Events are twinkle twinkle little star",
        to: "badminton",
        rounds: [
            {
                title: "Round 1",
                content: "Standard matches",
                duration: "4:00 PM to 7:00 PM",
                venue: "CEG Grounds",
                date: "18 & 19 March 2024"
            },
        ],
        contacts: [
            { name: 'Varsha', phno: '6382952323' },
            { name: 'Smrithi', phno: '8072869255' },
        ]
    },
];