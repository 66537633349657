import { useState, useEffect, useRef } from 'react';
import { GoChevronDown } from 'react-icons/go';
import { SlideFromTop } from '../../utils/Helper';
import classNames from 'classnames';
import { MdOutlineSearch } from "react-icons/md";
import './Dropdown.css';

function Panel({ children, className, searchText, handleSearch, bulk, ...rest }) {
    const finalClassnames = classNames(
        'p-2 outline-none border max-h-[11rem] dropdown overflow-y-auto border-[#ABB2BF]/50 text-[12px] sm:text-[14px] bg-[#30343a] text-white w-full',
        className
    );

    return (
        <SlideFromTop className={"absolute top-[125%] w-full z-10"}>
            <div {...rest} className={finalClassnames}>
                {bulk && (
                    <div className='relative'>
                        <input type='text' value={searchText} placeholder={`Search Abacus ID or by Name`}
                            className='p-2 mb-2 outline-none border bg-slate-600 border-[#ABB2BF]/50 text-[12px] sm:text-[18px]'
                            style={{ width: '100%', backgroundColor: "#30343a" }} onChange={handleSearch} />
                        <span className='absolute top-[10px] text-lg sm:text-2xl right-[10px]'><MdOutlineSearch /></span>
                    </div>
                )}
                {children}
            </div>
        </SlideFromTop>
    );
}

function Dropdown({ options, value, onChange, selectLabel, bulk }) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const [searchTimeOut, setSearchTimeOut] = useState(null);
    const divEl = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (!divEl.current) { return; }
            if (!divEl.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handler, true);
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);

    const handleClick = () => {
        setIsOpen((currentIsOpen) => !currentIsOpen);
    };

    const handleOptionClick = (option) => {
        setIsOpen(false);
        onChange(option);
    }

    const handleSearchChange = (e) => {
        clearTimeout(searchTimeOut);
        const searchTextValue = e.target.value;
        setSearchText(searchTextValue); setSearchTimeOut(
            setTimeout(() => {
                const searchResults = options.filter((item) => {
                    return item.label.toLowerCase().includes(searchTextValue.toLowerCase())
                        || item.value === parseInt(searchTextValue);
                })
                if (searchResults.length === 0) setSearchResults([{ label: 'No resuls found!', value: 999999 }]);
                else setSearchResults(searchResults);
            }, 100)
        );
    }

    const RenderedOptions = ({ options }) => {
        return (
            <>
                {options?.map((option) => (
                    <div className="hover:bg-[#C778DD33] select-none hover:text-white transition-all rounded cursor-pointer p-1" key={option.value} onClick={() => handleOptionClick(option)}>{option.label}</div>
                ))}
            </>
        )
    }

    return (
        <div ref={divEl} className="w-full relative">
            <div className="flex justify-between items-center cursor-pointer select-none p-2 outline-none border border-[#ABB2BF]/50 text-[12px] sm:text-[14px] bg-[#30343a] text-white w-full"
                onClick={handleClick}>{value?.label || selectLabel}
                <GoChevronDown className="text-lg" />
            </div>
            {isOpen && (
                <>
                    {!searchText ? (
                        <Panel handleSearch={handleSearchChange} searchText={searchText} bulk={bulk} className="rounded-md">
                            <RenderedOptions options={options} />
                        </Panel>
                    ) : (
                        <Panel handleSearch={handleSearchChange} searchText={searchText} bulk={bulk} className="rounded-md">
                            <RenderedOptions options={searchResults} />
                        </Panel>
                    )}
                </>
            )}
        </div>
    );
}

export default Dropdown;