import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import navImage from '../../assets/Hero/abacus_logo.png';
import Navigation from './Navbar-Mobile';

const Navbar = () => {
  const { auth } = useContext(AuthContext);

  return (
    <nav className="bg-navbar p-4 flex items-center font-sans justify-between text-white lg:relative">
      {/* Left side - Icon and Heading */}
      <Link to='/'>
        <div className="flex items-center gap-1">
          <img src={navImage} alt="Abacus Icon" className="h-10 mr-2" />
          <p className='text-xl font-bold'>ABACUS'24</p>
        </div>
      </Link>

      <div className="hidden lg:flex items-center space-x-4">
        <Link
          to="/"
          className={`text-[#C778DD] hover:text-white flex items-center`}
        >
          #
          <span className={`text-white hover:text-[#C778DD] transition-all duration-200`}>home</span>
        </Link>
        <Link
          to="/about"
          className={`text-[#C778DD] hover:text-white flex items-center`}
        >
          #
          <span className={`text-white  hover:text-[#C778DD] transition-all duration-200`}>about</span>
        </Link>
        <Link
          to="/sponsors"
          className={`text-[#C778DD] hover:text-white flex items-center`}
        >
          #
          <span className={`text-white  hover:text-[#C778DD] transition-all duration-200`}>sponsors</span>
        </Link>
        <Link
          to="/events"
          className={`text-[#C778DD] hover:text-white flex items-center`}
        >
          #
          <span className={`text-white  hover:text-[#C778DD] transition-all duration-200`}>events</span>
        </Link>
        <Link
          to="/workshops"
          className={`text-[#C778DD] hover:text-white flex items-center`}
        >
          #
          <span className={`text-white  hover:text-[#C778DD] transition-all duration-200`}>workshops</span>
        </Link>
        {!auth ? (
          <Link
            to="/login"
            className={`text-[#C778DD] hover:text-white flex items-center`}
          >
            #
            <span className={`text-white  hover:text-[#C778DD] transition-all duration-200`}>login</span>
          </Link>
        ) : (
          <Link
            to="/profile"
            className={`text-[#C778DD] hover:text-white flex items-center`}
          >
            #
            <span className={`text-white  hover:text-[#C778DD] transition-all duration-200`}>profile</span>
          </Link>
        )}
      </div>

      <div className="lg:hidden fixed bottom-0 w-full m-2 flex justify-center items-center mx-auto z-[999]" style={{ left: '0px' }}>
        <Navigation />
      </div>
    </nav>
  );
};

export default Navbar;
