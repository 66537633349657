import React from 'react';
import styles from './Loader.module.css';

function Loader() {
    return (
        <div className={styles.loader_wrapper}>
            <div className={styles.box}>
                <div className={styles.cube}></div>
                <div className={styles.cube}></div>
                <div className={styles.cube}></div>
                <div className={styles.cube}></div>
                {/* <p>Loading...</p> */}
            </div>
        </div>
    )
}

export default Loader;