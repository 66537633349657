import naren from '../assets/Devs/naren.jpg';
import nikhil from '../assets/Devs/nikhil.jpg';
import vishwanth from '../assets/Devs/vishwanth.jpg';
import kani from '../assets/Devs/kani.jpg';
import selva from '../assets/Devs/selva.jpg';

import { events } from './events';
import { workshops } from './workshops';
import { sponsorData } from './sponsor';

export const developersData = [
    {
        img: naren,
        title: "Narendran G",
        linkedin: "https://www.linkedin.com/in/narendran-g/",
        disc: "Frontend Developer",
    },
    {
        img: nikhil,
        title: "Nikhil Prasanna",
        linkedin: "https://www.linkedin.com/in/nikhil03a/",
        disc: "Backend Developer",
    },
    {
        img: vishwanth,
        title: "Vishwannth",
        linkedin: "https://www.linkedin.com/in/vishwannth-j/",
        disc: "Frontend Developer",
    },
    {
        img: kani,
        title: "Kanishkha",
        linkedin: "https://www.linkedin.com/in/kanishka-s-763062223/",
        disc: "UI & UX Developer",
    },
    {
        img: selva,
        title: "Selva Rathinam",
        linkedin: "http://linkedin.com/in/selvarathinam-m/",
        disc: "Backend Developer",
    },
    {
        img: naren,
        title: "Narendran G",
        linkedin: "https://www.linkedin.com/in/narendran-g/",
        disc: "Frontend Developer",
    },
    {
        img: nikhil,
        title: "Nikhil Prasanna",
        linkedin: "https://www.linkedin.com/in/nikhil03a/",
        disc: "Backend Developer",
    },
    {
        img: vishwanth,
        title: "Vishwannth",
        linkedin: "https://www.linkedin.com/in/vishwannth-j/",
        disc: "Frontend Developer",
    },
    {
        img: kani,
        title: "Kanishkha",
        linkedin: "https://www.linkedin.com/in/kanishka-s-763062223/",
        disc: "UI & UX Developer",
    },
    {
        img: selva,
        title: "Selva Rathinam",
        linkedin: "http://linkedin.com/in/selvarathinam-m/",
        disc: "Backend Developer",
    },
];

export const contactsData = [
    {
        name: "Saran Kumaran",
        phone: 9600544132,
    },
    {
        name: "Sruthi",
        phone: 8056284967,
    },
    {
        name: "Madhumithran",
        phone: 9566622358,
    },
    {
        name: "Sangami M",
        phone: 9942896494,
    },
];

export { events, workshops, sponsorData }