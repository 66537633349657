import MERN from '../assets/Reach/MERN.png';
import ML from '../assets/Reach/ML.png';
import cloud from '../assets/Reach/Cloud.png';

import ibm from '../assets/Events/ibm.png';
import walmart from '../assets/Events/Walmart.png';
import google from '../assets/Events/google.png';

import AI_ML_399 from '../assets/Events/AI_ML_399.jpg';
import Cloud_199 from '../assets/Events/Cloud_199.jpg';

import Thiyagarajan from '../assets/Speakers/Thiyagarajan.jpeg';
import Magesh from '../assets/Speakers/Magesh.jpg';
import kumarasen from '../assets/Speakers/kumaresan.jpg';

export const workshops = [
    {
        title: "Rapid Development with AI powered Tools",
        sponsor: "Walmart",
        sponsorImg: walmart,
        date: '22nd March (Friday), 2024',
        time: '9:00 AM to 4:30 PM',
        venue: 'X hall, EEE Department',
        fullDay: true,
        sponsorLink: "https://www.walmart.com",
        to: "walmart",
        image: ML,
        workshopClosed: true,
        bulkBooking: true,
        qr: AI_ML_399,
        price: 399,
        code: 3401,
        content: [
            "Join us for an insightful journey into the evolution of development in the age of AI. Discover how AI has transformed the development landscape, enabling rapid creation of applications for web, mobile, and server environments using cutting-edge tools.",
            "In this workshop, we'll explore the profound changes AI has brought to the development process. Learn how to leverage AI-powered tools to streamline your workflow and accelerate application development.",
            "Don't miss this opportunity to revolutionize your approach to application development. Register now and unlock the power of AI-powered tools!"
        ],
        prerequistes: "No prior knowledge is needed.",
        speakers: [
            {
                name: 'Thiyagarajan Balasubramaniam',
                job: 'Senior Engineering Manager',
                company: 'Walmart Global Tech India',
                image: Thiyagarajan
            }
        ]
    },
    {
        title: "Gen AI Unleashed",
        sponsor: "IBM",
        sponsorImg: ibm,
        date: '21st March (Thursday), 2024',
        time: '9:00 AM to 4:30 PM',
        venue: 'X hall, EEE Department',
        fullDay: true,
        sponsorLink: "https://www.ibm.com/in-en",
        to: "genai",
        image: MERN,
        workshopClosed: true,
        bulkBooking: true,
        qr: AI_ML_399,
        price: 399,
        code: 3402,
        content: [
            "Dive into the realm of Generative AI and unlock the potential of Large Language Models (LLMs) in this immersive workshop. From uncovering the mysteries of Transformer architecture to unraveling the secrets of attention mechanisms, this session promises an eye-opening journey into the heart of AI.",
            "But here's the twist - brace yourself for hands-on excitement! Delve into the world of fine-tuning techniques, experiment with cutting-edge methodologies, and witness the magic of LLMs in action.",
            "Don't miss your chance to be part of this cutting-edge exploration. Register now and unleash the potential of LLMs!"
        ],
        prerequistes: "No prior machine learning experience is required.",
        speakers: [
            {
                name: 'Magesh Rajamani',
                job: 'Solution Architect',
                company: 'IBM',
                image: Magesh
            }
        ]
    },
    {
        title: "Building a Cloud Home",
        tag: 'Deploying Apps on GCP Compute Engine',
        sponsor: "Google",
        sponsorImg: google,
        date: '21st March (Thursday), 2024',
        time: '9:00 AM to 12:30 PM',
        venue: 'Ground Floor Lab, DCSE',
        fullDay: false,
        to: "cloud",
        image: cloud,
        workshopClosed: true,
        bulkBooking: false,
        qr: Cloud_199,
        price: 199,
        code: 3403,
        content: [
            "Embark on a journey into cloud computing with our immersive workshop focused on Google Cloud Platform (GCP). Discover how to seamlessly deploy your applications to Compute Engine or Cloud Run and access them through a web browser, all within the GCP Cloud Console.",
            "In this half day workshop, you'll explore the intricacies of cloud deployment and learn best practices for leveraging GCP's powerful infrastructure. From setting up your environment to deploying your application, we'll guide you through every step of the process. But here's the exciting part - get ready to roll up your sleeves and dive into practical exercises! Get hands-on experience with industry-leading platforms and tools, and leave with the skills to elevate your projects to new heights.",
            "Don't miss out on this opportunity to elevate your cloud computing skills and unlock the full potential of Google Cloud Platform. Register now and take the first step towards mastering cloud computing on GCP!"
        ],
        prerequistes: "No priorcloud computing experience is required.",
        speakers: [
            {
                name: 'Kumaresan M K',
                job: 'Cloud Architect',
                company: 'Google',
                image: kumarasen
            }
        ]
    },
]